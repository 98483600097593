<template>
    <div class="app-container">
        <el-form :model="query" ref="queryForm" :inline="true" label-width="68px">
            <el-form-item label="用户名称" prop="nickname">
                <el-input v-model="query.nickname" placeholder="请输入用户名称" clearable size="small"
                    @change="handleQuery" />
            </el-form-item>
            <el-form-item label="手机号" prop="phone" >
                <el-input v-model="query.phone" placeholder="请输入用户名称" clearable size="small"
                    @change="handleQuery" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="reset">重置</el-button>
            </el-form-item>
        </el-form>

        <el-table v-loading="loading" :data="list" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" />
            <!-- <el-table-column label="会员编号" align="center" prop="id" /> -->
            <el-table-column label="会员昵称" align="center" prop="nickname" />
            <el-table-column label="商品图片" align="center" prop="skuInfo.skuDefaultImg">
                <template v-slot="slot">
                    <div v-if="slot.row.headImg == null || slot.row.headImg == ''">未上传头像</div>
                    <img :src="slot.row.headImg" width="80px" alt="image error" v-else>
                </template>
            </el-table-column>
            <el-table-column label="手机号" align="center" prop="phone" />
            <el-table-column label="余额" align="center" prop="money" >
                <template #default="scope">
                    <span style="font-size: 18px; color: red; font-weight: bold;">￥{{ (scope.row.money).toFixed(2) }}</span>
                </template>
            </el-table-column>

            <el-table-column label="操作" align="center" width="200" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-edit"
                        @click="viewDetail(scope.row)" >查看详情</el-button>

                </template>
            </el-table-column>
        </el-table>

        <pagination v-show="total > 0" :total="total" :page.sync="query.current" :limit.sync="query.size"
            @pagination="handleQuery" />

        <!-- 余额详情 -->
        <el-dialog title="余额明细" :visible.sync="dialogVisible" width="70%" center>
            <el-table :data="moneyInfoList">
                <el-table-column property="type" label="资金流向" >
                    <template #default="scope">
                        <el-tag>{{ scope.row.type == '0' ? '消费' : '充值' }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column property="money" label="金额">
                    <template #default="scope">
                        <span style="font-size: 18px; color: red; font-weight: bold;">￥{{ (scope.row.money).toFixed(2) }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="nowUserMoney" label="账号金额">
                    <template #default="scope">
                        <span style="font-size: 18px; color: red; font-weight: bold;">￥{{ (scope.row.nowUserMoney).toFixed(2) }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="orderSn" label="消费订单号"></el-table-column>
                <el-table-column property="topUpSn" label="充值流水号"></el-table-column>
                <el-table-column property="createTime" label="日期"></el-table-column>
            </el-table>
            <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="mini">关 闭</el-button>
            </span> -->
        </el-dialog>

    </div>
</template>

<script>
import api from '@/api/commonApi'

export default {
    name: 'UmsList',
    data() {
        return {
            // 列表
            list: [],
            // 类型
            dictType: [],
            // 总条数
            total: 0,
            // 遮罩层
            loading: true,
            // 查询
            query: {
                current: 1,
                size: 10,
                nickname: '',
                phone: '',
            },
            // 选择
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 详情弹窗开关
            dialogVisible: false,
            // 资金详情数据
            moneyInfoList: [],
            
        }
    },
    created() {
        this.getList()
    },
    methods: {
        // 获取列表数据
        getList() {
            this.loading = true
            api.memberList(this.query).then(res => {
                this.total = res.total
                this.list = res.records
                this.loading = false
            })
        },
        // 搜索
        handleQuery() {
            this.getList()
        },
        // 重置
        reset() {
            this.query.current = 1
            this.query.nickname = '',
            this.query.phone = '',
            this.getList()
        },
        /** 多选框选中数据 */
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
        /** 查看详情 */
        viewDetail(data) {
            api.userMoneyInfo({
                current: 1,
                size: 1000,
                memberId: data.id,
            }).then(res => {
                this.moneyInfoList = res.records
                this.dialogVisible = true
            })
        }
    }
}

</script>